import React from "react";
import heroBG from "@/public/images/assets/heroBG.svg";
import logo from "@/public/images/logos/mainLogo.svg";
import Image from "next/image";
type Props = {
  children: React.ReactNode;
  title: string;
  description: string;
};

export default function AuthCard({ children, title, description }: Props) {
  return (
    <div className=" overflow-hidden relative ">
      <div
        className="absolute inset-0 min-h-screen h-screen z-[-1]"
        style={{
          backgroundImage: `url(${heroBG.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.2,
          pointerEvents: "none", // Disables click events on the overlay
        }}
      ></div>
      <span className="z-10 w-full max-w-[60vw] h-[300px] -rotate-45 absolute top-0 left-1/2  -translate-x-[55%] bg-[#8F6AF8] opacity-50 blur-[160px] rounded-full"></span>

      <div className="max-w-xl w-full h-screen mx-auto flex flex-col items-center justify-center ">
        <div className=" bg-[#07031A] w-full md:w-[600px] rounded-[16px] flex flex-col items-center justify-center p-[32px]  ">
          {/* logo */}
          <div className="flex gap-2 items-center ">
            <Image src={logo} alt="logo" width={50} height={50} />
            <h2 className="text-2xl  text-purple-200">Vulnvision</h2>
          </div>
          <div className=" mt-[25px]">
            <h3 className=" text-center text-[18px] font-[500] text-[#FFFFFF]">
              {title}
            </h3>
            <p className="mt-2 text-center text-[16px]  font-[500] text-[#938DB5]">
              {`${description}`}
            </p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
