// TextInput.tsx

import { useState, FC } from "react";
import { Eye, EyeOff } from "lucide-react";
import Image from "next/image";

interface TextInputProps {
  label: string;
  id: string;
  type?: string;
  placeholder: string;
  icon?: any;
  register: any;
  error?: any;
  isPassword?: boolean;
  handleUpdateLicenseKey?: any;
  licenseStatus?: boolean;
}

const TextInput: FC<TextInputProps> = ({
  label,
  id,
  type = "text",
  placeholder,
  icon,
  register,
  error,
  isPassword = false,
  handleUpdateLicenseKey,
  licenseStatus,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex flex-col items-start justify-center w-full">
      <label htmlFor={id} className="text-purple-100 mb-2">
        {label}
      </label>
      <div className="relative w-full h-[60px]">
        {icon && (
          <span className="absolute left-[15px] top-1/2 transform -translate-y-1/2 text-purple-400">
            <Image src={icon} alt="icon" width={24} height={24} />
          </span>
        )}
        <input
          id={id}
          type={isPassword && showPassword ? "text" : type}
          placeholder={placeholder}
          {...register(id)}
          className="w-full bg-[#110D24] border border-[#1B1239] text-white placeholder-purple-300 pl-12 pr-6 py-5 rounded-[15px]  focus:outline-none focus:ring-2 focus:ring-purple-500"
        />

        {id == "licenseKey" ? (
          licenseStatus ? (
            <span
              onClick={handleUpdateLicenseKey}
              className="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 hover:bg-[#0CAF60]/30 transition-all bg-[#0CAF60]/10 px-[14px] rounded-[12px] py-[7px] text-[#0CAF60] focus:outline-none"
            >
              update license Key
            </span>
          ) : (
            <span
              onClick={handleUpdateLicenseKey}
              className="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 hover:bg-[#0CAF60]/30 transition-all bg-[#0CAF60]/10 px-[14px] rounded-[12px] py-[7px] text-[#0CAF60] focus:outline-none"
            >
              active
            </span>
          )
        ) : null}
        {isPassword && (
          <div
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-purple-400 focus:outline-none"
          >
            {showPassword ? <EyeOff /> : <Eye />}
          </div>
        )}
        {error && <span className="text-red-500">{error}</span>}
      </div>
    </div>
  );
};

export default TextInput;
